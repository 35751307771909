<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_deal_detail relative w1239" v-if="dataobj">
			<div class="detail_top">
				<div class="left phonenone">
					<!-- <div class="imgdiv"><img :src="imageList[curimg] || require('../assets/static/noimg.png')"/></div> -->
					<div class="imgdiv">
						<el-image
						   class="imgdiv"
						   :src="imageList[curimg] || require('../assets/static/noimg.png')"
						    :preview-src-list="imageList">
						</el-image>
					</div>
					<div class="swiper relative" v-if="swiperOptions1.isshow">
					    <div class="swiper_con">
							<swiper ref="mySwiper1" :options="swiperOptions1">
							    <swiper-slide
							        class="swiperslide"
							        v-for="(item,index) in imageList"
							        :key="index">
									<img class="img" @click="handleImg(index)" :class="{'hover':index==curimg}" :src="item || require('../assets/static/logo.png')" />
							    </swiper-slide>
							</swiper>
						</div>
						<div class="prev pointer fontsize22" v-html="'<'" @click="slidePrev1"></div>
						<div class="next pointer fontsize22" v-html="'>'" @click="slideNext1"></div>
					</div>
				</div>
				<div class="left pcnone">
					<div class="swiper relative" v-if="swiperOptions2.isshow">
					    <div class="swiper_con">
							<swiper ref="mySwiper1" :options="swiperOptions2">
							    <swiper-slide
							        class="swiperslide"
							        v-for="(item,index) in imageList"
							        :key="index">
									<el-image
									   class="img"
									   :src="item || require('../assets/static/noimg.png')"
									   :preview-src-list="imageList">
									</el-image>
							    </swiper-slide>
							</swiper>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="titlename">
						<span class="text fontsize30">{{dataobj.title}}</span>
						<span class="text3 fontsize18" v-for="(item,index) in dataobj.tags" :key="index">{{item}}</span>
					</div>
					<div class="body_con">
						<div class="conobj">
							<div class="text1 fontsize18">申请号：</div>
							<div class="text2 fontsize18">{{dataobj.applyNo}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">申请日：</div>
							<div class="text2 fontsize18">{{dataobj.applyDate}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">公开(公告)号：</div>
							<div class="text2 fontsize18">{{dataobj.openNo}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">公开(公告)日：</div>
							<div class="text2 fontsize18">{{dataobj.openDate}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">申请(专利权)人：</div>
							<div class="text2 fontsize18">{{dataobj.applicant}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">知识产权类型：</div>
							<div class="text2 fontsize18">{{dataobj.rightType.name}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">产业分类：</div>
							<div class="text2 fontsize18">{{dataobj.technicalField.name}}</div>
						</div>
						<div class="conobj" v-if="dataobj.productTypeId">
							<div class="text1 fontsize18">产品分类：</div>
							<div class="text2 fontsize18">{{dataobj.productType.name}}</div>
						</div>
						<div class="conobj" v-if="dataobj.technicalFieldArea">
							<div class="text1 fontsize18">技术领域：</div>
							<div class="text2 fontsize18">{{dataobj.technicalFieldArea}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">法律状态：</div>
							<div class="text2 fontsize18">{{dataobj.legalStatus.name}}</div>
						</div>
						<div class="conobj">
							<div class="text1 fontsize18">剩余保护有效期：</div>
							<div class="text2 fontsize18">{{dataobj.overTime.name}}</div>
						</div>
					</div>
					<div class="price fontsize38"><span class="fontsize30">￥</span>{{dataobj.price || "面议"}}</div>
					<div class="more fontsize14" @click="$util.routerPath('/mine',{type:2})">马上咨询</div>
				</div>
			</div>
			<!-- 详细信息 -->
			<div class="block45"></div>
			<div class="detail_center">
				<div class="left">
					<div class="titlediv fontsize24">
						<div class="text">详细消息</div>
					</div>
					<div class="data_con">
						<div class="fontsize16" v-html="dataobj.patentBody"></div>
					</div>
					<div class="block30"></div>
					<div class="block10" v-if="dataobj.serviceProcess"></div>
					<div class="titlediv fontsize16" v-if="dataobj.serviceProcess">
						<div class="text">服务流程</div>
					</div>
					<div class="data_con" v-if="dataobj.serviceProcess">
						<div class="fontsize16" v-html="dataobj.serviceProcess"></div>
					</div>
				</div>
				<!-- <div class="right">
					<div class="titlediv fontsize16">
						<div class="text">热门推荐</div>
					</div>
					<div class="conobj" v-for="(item,index) in dataobj.nextPatentVoList" :key="index" @click="clickarticleDetail(item.id)">
						<div class="img"><img :src="item.imgUrl || require('../assets/static/noimg.png')"/></div>
						<div class="title fontsize16">{{item.title}}</div>
					</div>
				</div> -->
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
//导入swiper
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
	components: {
	    swiper,
	    swiperSlide
	},
	data() {
		return {
			uid:0,//详情
			dataobj:null,
			swiperOptions1:{
				isshow:true,
				slidesPerView: 4,
			},
			swiperOptions2:{
				isshow:true,
				// loop:true,
				slidesPerView: 1,
				autoplay: {
				    delay: 3000, //自动切换的时间间隔，单位ms
				},
			},
			imageList:[],
			curimg:0,//当前下标
		};
	},
	mounted: function() {
		if(document.body.clientWidth<750){
			this.swiperOptions1.isshow = false
			// console.log(this.swiperOptions2.slidesPerView)
		}else{
			this.swiperOptions2.isshow = false
		}
		this.$store.commit('SET_CURPAGE', "deal")
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//点击图片
		handleImg(index){
			this.curimg = index
		},
		//上一页
		slidePrev1() {
		    this.$refs.mySwiper1.swiper.slidePrev();
		},
		//下一页
		slideNext1() {
		    this.$refs.mySwiper1.swiper.slideNext()
		},
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/dealDetail', query: {uid: uid }})
			this.$router.go(0);
		},
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/transaction/transactionById', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						if(dataobj.imgUrlList){
							try{
								_this.imageList = JSON.parse(dataobj.imgUrlList)
							}catch(e){
								_this.imageList = [""]
							}
						}
						if(dataobj.imgUrl){
							_this.imageList.push(dataobj.imgUrl)
						}
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
